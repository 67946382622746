// Supermove
import {ScreenOrientationType} from '@supermove/sdk/src/ScreenOrientation/ScreenOrientationTypes';

const ScreenOrientation: ScreenOrientationType = {
  lockToLandscape: () => {},

  lockToPortrait: () => {},

  unlockAllOrientations: () => {},
};

export default ScreenOrientation;
