// Libraries
import React from 'react';

import {Permissions} from '@supermove/sdk';

interface PermissionsViewProps {
  type: string;
  children: (props: {
    status: string;
    isAuthorized: boolean;
    isDenied: boolean;
    isRestricted: boolean;
    isUndetermined: boolean;
  }) => React.ReactNode;
}

class PermissionsView extends React.Component<PermissionsViewProps> {
  protected mounted = false;

  state = {
    status: Permissions.RESULTS.UNDETERMINED,
  };

  componentDidMount() {
    this.mounted = true;
    this.updateStatus();
  }

  componentDidUpdate(previousProps: PermissionsViewProps) {
    this.updateStatus();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateStatus = async () => {
    const {type} = this.props;
    const status = await Permissions.check(type);

    if (this.mounted && status !== this.state.status) {
      this.setState({status});
    }
  };

  render() {
    const {status} = this.state;
    return this.props.children({
      status,
      isAuthorized: status === Permissions.RESULTS.AUTHORIZED,
      isDenied: status === Permissions.RESULTS.DENIED,
      isRestricted: status === Permissions.RESULTS.RESTRICTED,
      isUndetermined: status === Permissions.RESULTS.UNDETERMINED,
    });
  }
}

export default PermissionsView;
