module.exports = [{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-react-x/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"0e556bc9877e3d62084abe5cf9211623"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/apps/crew/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
