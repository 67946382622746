// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {openNewTab} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const ContentContainer = Styled.View`
`;

const IconContainer = Styled.ButtonV2`
`;

const addProtocol = (value: string): string => {
  // do not sanitize if there's a custom protocol (eg. mailto: or tel:) or if it contains http or https
  const startsWithWordsRegex = /^\w+:/;
  const startsWithHttpsRegex = /^https?:/;
  const startsWithSlashesRegex = /^\/\//;

  if (
    !startsWithWordsRegex.test(value) &&
    !startsWithHttpsRegex.test(value) &&
    !startsWithSlashesRegex.test(value)
  ) {
    value = `//${value}`;
  }
  return value;
};

const ManageLinkModal = ({
  removeLink,
  updateLink,
  initialLink,
  isOpen,
  handleClose,
}: {
  removeLink: () => void;
  updateLink: (newText?: string) => void;
  initialLink: string;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const [linkValue, setLinkValue] = useState<string>();

  const handleSubmit = () => {
    if (linkValue) {
      const linkWithProtocol = addProtocol(linkValue);
      updateLink(linkWithProtocol);
    } else {
      removeLink();
    }
    handleClose();
  };

  const handleRemoveLink = () => {
    removeLink();
    handleClose();
  };

  // Whenever the menu opens, update the linkValue to the initialLink
  useEffect(() => {
    if (isOpen) {
      setLinkValue(initialLink);
    }
  }, [isOpen, initialLink]);

  const handleLinkPress = () => {
    if (linkValue) {
      const valueWithProtocol = addProtocol(linkValue);
      openNewTab(valueWithProtocol);
    }
  };

  const isLinkEmpty = !linkValue;

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Link To'}
      handleAction={handleSubmit}
      handleActionText={'Save'}
      headerActionText={'Clear'}
      headerActionIcon={Icon.Trash}
      handleHeaderAction={handleRemoveLink}
    >
      <ContentContainer>
        <FieldInput
          handleEnter={handleSubmit}
          input={{
            placeholder: 'https://',
            onChangeText: setLinkValue,
            autoFocus: true,
            value: linkValue ?? '',
            backgroundColor: 'transparent',
          }}
        />
        <IconContainer
          disabled={isLinkEmpty}
          onPress={handleLinkPress}
          style={{position: 'absolute', top: 10, right: 20, zIndex: 101}}
        >
          <Icon
            color={isLinkEmpty ? colors.gray.disabled : colors.gray.tertiary}
            source={Icon.ArrowUpRightFromSquare}
          />
        </IconContainer>
      </ContentContainer>
      <Space height={16} />
    </SmallModal>
  );
};

export default ManageLinkModal;
