// Libraries
import {useMemo} from 'react';

// Navigation
import {NavigationType} from '../NavigationTypes';
import {getActiveRoute, getActiveOptions} from '../utils';

const useActiveRoute = ({navigation}: {navigation: NavigationType}) => {
  const route = useMemo(() => getActiveRoute(navigation.state), [navigation]);
  const options = useMemo(() => getActiveOptions(navigation), [navigation]);

  return {
    route,
    options,
  };
};

export default useActiveRoute;
