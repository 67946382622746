// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

const PreventPropagationContainer = Styled.View`
  flex-shrink: 1;
`;

const PREVENT_PROPAGATION_KEYS = ['Enter', 'Space', ' '];

const PreventPropagation = ({
  children,
  style,
  isMobileDisabled,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;

  // On mobile the PreventPropagation could force the user to have to press twice on an
  // element when interacting with different items in a list. Preventing the click event
  // from being passed to the parent component is not an issue on desktop because the user
  // can hover over the other list item and then click the button without the click event
  // being passed to the parent component.
  isMobileDisabled?: boolean;
}) => {
  const responsive = useResponsive();
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (PREVENT_PROPAGATION_KEYS.includes(e.key)) {
      e.stopPropagation();
    }
  };

  return (
    <PreventPropagationContainer
      onStartShouldSetResponder={() => true}
      onTouchEnd={(e) => (responsive.desktop || !isMobileDisabled) && e.stopPropagation()}
      style={style}
    >
      <div onKeyDown={handleKeyDown} role={'presentation'}>
        {children}
      </div>
    </PreventPropagationContainer>
  );
};

export default PreventPropagation;
