// Libraries
import {Editor} from '@tiptap/react';
import React from 'react';

// Supermove
import {DropdownInput, Icon, IconSource, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';

import {findAttribute} from '../utils/findAttribute';
import {markTypes} from '../utils/markTypes';

import ColorPicker from './ColorPicker';
import ManageLinkModal from './ManageLinkModal';

const ToolbarContainer = Styled.View`
    padding-vertical: 8px;
    z-index: 5;
    margin-horizontal: 8px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const ItemWrapperContainer = Styled.View<{index: number}>`
    padding: 4px;
    z-index: ${({index}) => 100 - index};
`;

const Button = Styled.ButtonV2`
`;

const FONT_SIZE_OPTIONS = [
  '6px',
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

const HEADER_1 = 'Heading 1';
const HEADER_2 = 'Heading 2';
const NORMAL = 'Normal';

const HEADER_OPTIONS = [HEADER_1, HEADER_2, NORMAL] as const;
type HeaderOptionType = (typeof HEADER_OPTIONS)[number];

const ALIGN_OPTIONS = ['left', 'center', 'right', 'justify'] as const;
type AlignOptionType = (typeof ALIGN_OPTIONS)[number];
const alignOptionToIcon = {
  left: Icon.AlignLeft,
  center: Icon.AlignCenter,
  right: Icon.AlignRight,
  justify: Icon.AlignJustify,
};

const defaultFontsForHeading = {
  [HEADER_1]: '18px',
  [HEADER_2]: '14px',
  [NORMAL]: '14px',
};

const ItemWrapper = ({
  children,
  index,
  style,
}: {
  children: React.ReactNode;
  index: number;
  style?: React.CSSProperties;
}) => {
  return (
    <ItemWrapperContainer index={index} style={style}>
      {children}
    </ItemWrapperContainer>
  );
};

const IconButton = ({
  icon,
  isActive,
  onToggle,
  index,
}: {
  icon: IconSource;
  isActive?: boolean;
  onToggle: () => void;
  index: number;
}) => {
  return (
    <ItemWrapper index={index}>
      <Button onPress={onToggle}>
        <Icon source={icon} size={14} color={isActive ? 'blue' : 'black'} />
      </Button>
    </ItemWrapper>
  );
};

const Dropdown = (
  props: Omit<React.ComponentProps<typeof DropdownInput>, 'placeholder' | 'name' | 'setFieldValue'>,
) => {
  return (
    <DropdownInput
      placeholder={''}
      isSearchable={false}
      isPortaled
      {...props}
      style={{cursor: 'default', ...props.style}}
    />
  );
};

interface RichTextEditorToolbarProps {
  editor: Editor;
  hasAdvancedFormatting?: boolean;
}

const RichTextEditorToolbar = ({editor, hasAdvancedFormatting}: RichTextEditorToolbarProps) => {
  const manageLinkModal = useModal({name: 'Manage Link Modal'});

  if (!editor) {
    return null;
  }

  const activeTextAlign: AlignOptionType | undefined = (() => {
    return ALIGN_OPTIONS.find((align) => editor.isActive({textAlign: align})) ?? 'left';
  })();
  const activeHeading: HeaderOptionType | undefined = (() => {
    if (editor.isActive('heading', {level: 1})) {
      return HEADER_1;
    } else if (editor.isActive('heading', {level: 2})) {
      return HEADER_2;
    }
    return NORMAL;
  })();
  const activeFontSize =
    editor.getAttributes('textStyle')?.fontSize || defaultFontsForHeading[activeHeading];

  const setLink = (newLink?: string) => {
    if (!newLink) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    } else {
      const {from, to} = editor.state.selection;
      const selectedText = editor.state.doc.textBetween(from, to, '\n');
      // If no text is selected, insert the link
      if (!selectedText) {
        const textToInsert = newLink.replace(/^\/\//, '');
        editor
          .chain()
          .focus()
          .insertContentAt(from, textToInsert)
          .setTextSelection({from, to: from + textToInsert.length})
          .run();
      }
      editor.chain().focus().extendMarkRange('link').setLink({href: newLink}).run();
    }
  };

  return (
    <ToolbarContainer>
      <ItemWrapper index={0}>
        <Dropdown
          options={HEADER_OPTIONS.map((option) => ({label: option, value: option}))}
          value={activeHeading}
          onChangeValue={(newHeading) => {
            if (newHeading === HEADER_1) {
              editor.chain().focus().setHeading({level: 1}).run();
            } else if (newHeading === HEADER_2) {
              editor.chain().focus().setHeading({level: 2}).run();
            } else {
              editor
                .chain()
                .focus()
                .toggleHeading({level: editor.getAttributes('heading')?.level})
                .run();
            }
          }}
          style={{width: 120}}
        />
      </ItemWrapper>
      {hasAdvancedFormatting ? (
        <ItemWrapper index={1}>
          <Dropdown
            options={FONT_SIZE_OPTIONS.map((size) => ({label: size, value: size}))}
            value={activeFontSize}
            isSearchable={false}
            onChangeValue={(newFontSize) => {
              editor.chain().focus().setFontSize(`${newFontSize}`).run();
            }}
            style={{width: 90}}
          />
        </ItemWrapper>
      ) : null}
      <IconButton
        onToggle={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        icon={Icon.Bold}
        index={2}
      />
      <IconButton
        onToggle={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('italic')}
        icon={Icon.Italic}
        index={3}
      />
      <IconButton
        onToggle={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive('underline')}
        icon={Icon.Underline}
        index={5}
      />
      {hasAdvancedFormatting ? (
        <IconButton
          onToggle={() => editor.chain().focus().toggleStrike().run()}
          isActive={editor.isActive('strike')}
          icon={Icon.Strikethrough}
          index={4}
        />
      ) : null}
      <IconButton
        onToggle={manageLinkModal.handleOpen}
        isActive={editor.isActive('link')}
        icon={Icon.Link}
        index={5}
      />
      {hasAdvancedFormatting ? (
        // Negative margin makes up for the space added by the dropdown
        <ItemWrapper index={6} style={{marginLeft: -4, marginRight: -12}}>
          <Dropdown
            options={ALIGN_OPTIONS.map((option) => ({
              label: <Icon source={alignOptionToIcon[option]} />,
              value: option,
            }))}
            value={activeTextAlign}
            onChangeValue={(newAlign) => {
              editor
                .chain()
                .focus()
                .setTextAlign(newAlign || 'left')
                .run();
            }}
            DropdownIndicatorComponent={() => null}
            style={{width: 40, borderWidth: 0, cursor: 'pointer'}}
          />
        </ItemWrapper>
      ) : null}
      <ItemWrapper index={7}>
        <IconButton
          onToggle={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
          icon={Icon.ListOl}
          index={10}
        />
      </ItemWrapper>
      <ItemWrapper index={8} style={{marginLeft: -4, marginRight: -8}}>
        <IconButton
          onToggle={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
          icon={Icon.ListUl}
          index={9}
        />
      </ItemWrapper>
      {hasAdvancedFormatting ? (
        <ItemWrapper index={9} style={{marginLeft: -4, marginRight: -16}}>
          <ColorPicker
            onChangeValue={(color) => editor.chain().focus().setColor(color).run()}
            value={findAttribute(editor, markTypes, 'color')}
            type={'text'}
            defaultColor={'#000000'}
          />
        </ItemWrapper>
      ) : null}
      {hasAdvancedFormatting ? (
        <ItemWrapper index={10} style={{marginLeft: -4, marginRight: -16}}>
          <ColorPicker
            onChangeValue={(color) => editor.chain().focus().setBackgroundColor(color).run()}
            value={findAttribute(editor, markTypes, 'backgroundColor')}
            defaultColor={'#ffffff'}
            type={'background'}
          />
        </ItemWrapper>
      ) : null}
      <ItemWrapper index={11}>
        <IconButton
          onToggle={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
          icon={Icon.TextSlash}
          index={8}
        />
      </ItemWrapper>
      <ManageLinkModal
        isOpen={manageLinkModal.isOpen}
        handleClose={manageLinkModal.handleClose}
        removeLink={setLink}
        updateLink={setLink}
        initialLink={editor.getAttributes('link').href}
      />
    </ToolbarContainer>
  );
};

export default RichTextEditorToolbar;
