// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';

const IconContainer = Styled.ButtonV2`
`;

const ColorPickerContainer = Styled.View`
    padding: 4px;
    width: 200px;
`;

const ColorRow = Styled.View`
    flex-direction: row;
    align-items: center;
    padding: 4px;
`;

const ColorContainer = Styled.ButtonV2<{color: string; isHovered: boolean}>`
    height: 16px;
    width: 16px;
    background-color: ${({color}) => color};
    margin-right: 4px;
    cursor: pointer;
    border: 1px solid ${({isHovered}) => (isHovered ? 'black' : 'transparent')};
`;

const rgbToHex = (rgbString?: string) => {
  if (!rgbString) {
    return undefined;
  }
  const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!match) {
    console.error('Invalid RGB string', rgbString);
    return undefined;
  }

  // Destructure the matched groups and convert to HEX
  const [_, r, g, b] = match.map(Number); // Convert strings to numbers
  const toHex = (value: number) => value.toString(16).padStart(2, '0'); // Ensure two digits

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
const colorOptions = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];

type ColorPickerType = 'background' | 'text';

interface ColorPickerProps {
  value?: string;
  onChangeValue: (color: string) => void;
  type: ColorPickerType;
  defaultColor: string;
}

const ColorSquare = ({color, onPress}: {color: string; onPress: (color: string) => void}) => {
  const {isHovered, ref} = useHover();
  return (
    <ColorContainer ref={ref} isHovered={isHovered} onPress={() => onPress(color)} color={color} />
  );
};

const ColorPicker = ({value, onChangeValue, type, defaultColor}: ColorPickerProps) => {
  const hexValue = (value?.includes('(') ? rgbToHex(value) : value) || defaultColor;
  const icon = type === 'text' ? Icon.A : Icon.Highlighter;

  // Don't want to make the icon disappear when the color is white. This is lighter than the next lightest gray
  const displayColor = value?.toLowerCase() === '#ffffff' ? '#eeeeee' : value;

  return (
    <React.Fragment>
      <DropdownInput
        options={colorOptions.map((option) => ({
          label: (
            <IconContainer style={{cursor: 'pointer'}}>
              <Icon source={icon} color={value ? displayColor : undefined} />
            </IconContainer>
          ),
          value: option,
        }))}
        placeholder=''
        value={hexValue}
        isPortaled
        onChangeValue={(value) => {
          value && onChangeValue(value);
        }}
        components={{
          MenuList: () => {
            const groupedOptions = _.chunk(colorOptions, 7);
            return (
              <ColorPickerContainer>
                {groupedOptions.map((colorRow, index) => {
                  return (
                    <ColorRow key={index}>
                      {colorRow.map((color) => {
                        return <ColorSquare onPress={onChangeValue} key={color} color={color} />;
                      })}
                    </ColorRow>
                  );
                })}
              </ColorPickerContainer>
            );
          },
        }}
        DropdownIndicatorComponent={() => null}
        style={{width: 45, borderWidth: 0}}
        menuStyle={{width: 150}}
        isSearchable={false}
      />
    </React.Fragment>
  );
};

export default ColorPicker;
