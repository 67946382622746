// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// @ts-expect-error library is not typed
import Select, {createFilter, components as ReactSelectComponents} from 'react-select';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {useMountEffect, useResponsive} from '@supermove/hooks';
import {colors, INPUT_BORDER_COLOR, Typography} from '@supermove/styles';

const controlStyle = {
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: 'none',
  borderColor: INPUT_BORDER_COLOR,
};

const defaultOptionStyle = {
  display: 'block',
  lineHeight: '19px',
  minHeight: '35px',
  fontWeight: 500,
  marginTop: '2px',
  marginBottom: '2px',
  cursor: 'pointer',
};

const defaultPlaceholderStyle = {
  fontFamily: 'Avenir',
  lineHeight: '19px',
  fontWeight: 500,
  color: INPUT_BORDER_COLOR,
};

const singleValueStyle = {
  fontFamily: 'Avenir',
  fontWeight: 500,
};

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const OptionLabel = Styled.Text<{color: string}>`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const OptionDescription = Styled.Text<{color: string}>`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const GroupContainer = Styled.View`
  flex: 1;
`;

const GroupLabelText = Styled.Text`
  ${Typography.Label}
  text-transform: capitalize;
`;

const GroupDivider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const TooltipText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

// TextTooltip cannot be imported from @shared/javascript/design/components/TextTooltip
// because it breaks the build. We've defined a lightweight tooltip to be used here only
// by the DropdownInput component.
const TextTooltip = ({
  text,
  children,
  props,
}: {
  text?: string;
  children: string | React.ReactNode;
  props: {isSelected: boolean; isDisabled: boolean};
}) => {
  const wrappedChildren =
    typeof children === 'string' ? <Label {...props}>{children}</Label> : children;
  return text ? (
    <Tooltip
      overlay={<TooltipText>{text}</TooltipText>}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      overlayStyle={{maxWidth: '20%'}}
      children={<Container>{wrappedChildren}</Container>}
    />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

interface DropdownState {
  data: {
    color: string;
  };
  isFocused: boolean;
  hasValue: boolean;
  getValue: () => any;
}

interface DropdownOption {
  label: string;
  data: {
    description: string;
    secondaryLabel: string;
    color: string;
  };
  value: string;
}

const getBackgroundColor = ({
  disabled,
  required,
  backgroundColor,
}: {
  disabled?: boolean;
  required?: boolean;
  backgroundColor?: string;
}) => {
  if (disabled) {
    return colors.gray.disabled;
  }
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  if (backgroundColor) {
    return backgroundColor;
  }
  return colors.white;
};

const getSingleValueColor = ({state}: {state: DropdownState}) => {
  const {data} = state;
  if (data.color) {
    return data.color;
  }
  return colors.gray.primary;
};

// NOTE(cooper): Discreet is a special dropdown style,
// in the future we may want to move this into it's own design system file
const getDiscreetControlStyle = ({
  state,
  placeholder,
  isHovered,
}: {
  state: DropdownState;
  placeholder: string;
  isHovered?: boolean;
}) => {
  const baseDiscreetControlStyle = {
    height: 25,
    minHeight: 20,
    maxWidth: 135,
    borderWidth: 0,
    ...(isHovered || state.isFocused
      ? {
          boxShadow: '0 0 0 1px #2684ff',
          paddingLeft: 5,
          paddingRight: 2,
        }
      : {}),
  };

  if (state.hasValue) {
    const selectedValue = state.getValue()[0];
    return {
      ...baseDiscreetControlStyle,
      width: `${10 * selectedValue.label.length + 20}px`,
    };
  }

  // If no value exists, use the width of the placeholder
  return {
    ...baseDiscreetControlStyle,
    width: `${8 * placeholder.length + 20}px`,
  };
};

const filterConfigForDescriptionInOption = {
  // This tells react-select what value to search options on
  stringify: (option: DropdownOption) => {
    return `${option.label} ${option.data.description}`;
  },
};

const Label = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  isSelected: boolean;
  isDisabled: boolean;
}) => {
  return (
    <OptionLabel
      numberOfLines={1}
      color={
        props.isSelected
          ? colors.white
          : props.isDisabled
            ? colors.gray.tertiary
            : colors.gray.primary
      }
    >
      {children}
    </OptionLabel>
  );
};

const SecondaryLabel = ({children}: {children: React.ReactNode}) => {
  return <OptionLabel color={colors.gray.tertiary}>{children}</OptionLabel>;
};

const OptionWithDescription = ({
  data,
  ...props
}: {
  data: {
    label: string;
    description: string;
    isDisabled: boolean;
    tooltip?: string;
  };
  isSelected: boolean;
  isDisabled: boolean;
}) => {
  const {label, description, isDisabled, tooltip} = data;
  return (
    <ReactSelectComponents.Option {...props}>
      <TextTooltip text={tooltip} props={props}>
        <Row>
          <Label {...props}>{label}</Label>
        </Row>
        <Row>
          <OptionDescription
            color={props.isSelected || isDisabled ? colors.gray.disabled : colors.gray.secondary}
          >
            {description}
          </OptionDescription>
        </Row>
      </TextTooltip>
    </ReactSelectComponents.Option>
  );
};

const OptionWithDescriptionOnRight = ({
  data,
  ...props
}: {
  data: {
    label: string;
    description: string;
    tooltip?: string;
  };
  isSelected: boolean;
  isDisabled: boolean;
}) => {
  const {label, description, tooltip} = data;
  return (
    <ReactSelectComponents.Option {...props}>
      <TextTooltip text={tooltip} props={props}>
        <Row>
          <Label {...props}>{label}</Label>
          <Space style={{flex: 1}} />
          <OptionLabel
            numberOfLines={1}
            color={props.isSelected ? colors.gray.disabled : colors.gray.secondary}
          >
            {description}
          </OptionLabel>
        </Row>
      </TextTooltip>
    </ReactSelectComponents.Option>
  );
};

const OptionWithDescriptionAndSecondaryLabel = ({
  data,
  ...props
}: {
  data: {
    label: string;
    description: string;
    secondaryLabel: string;
    isDisabled: boolean;
    tooltip?: string;
  };
  isSelected: boolean;
  isDisabled: boolean;
}) => {
  const {label, description, secondaryLabel, isDisabled, tooltip} = data;
  return (
    <ReactSelectComponents.Option {...props}>
      <TextTooltip text={tooltip} props={props}>
        <Row>
          <Label {...props}>{label}</Label>
          <Space style={{flex: 1}} />
          <OptionLabel
            numberOfLines={1}
            color={props.isSelected ? colors.gray.disabled : colors.gray.secondary}
          >
            {secondaryLabel}
          </OptionLabel>
        </Row>
        <Row>
          <OptionDescription
            color={props.isSelected || isDisabled ? colors.gray.disabled : colors.gray.secondary}
          >
            {description}
          </OptionDescription>
        </Row>
      </TextTooltip>
    </ReactSelectComponents.Option>
  );
};

const Option = ({
  ...props
}: {
  children: React.ReactNode;
  data: {
    menuLabel: string;
    label: string;
    tooltip?: string;
  };
  isSelected: boolean;
  isDisabled: boolean;
}) => {
  // Use menuLabel when the menu option text is different from the input selection text
  const optionLabel = props.data && (props.data.menuLabel || props.data.label);

  return (
    <ReactSelectComponents.Option {...props}>
      <TextTooltip text={props.data?.tooltip} props={props}>
        {optionLabel || props.children}
      </TextTooltip>
    </ReactSelectComponents.Option>
  );
};

const GroupLabel = ({label}: {label: string}) => {
  return (
    <React.Fragment>
      <GroupLabelText>{label}</GroupLabelText>
      <Space height={8} />
    </React.Fragment>
  );
};

const handleChange = ({
  setFieldValue,
  name,
  newValue,
  option,
  prevValue,
  onChangeValue,
}: {
  setFieldValue?: (name: string | undefined, value: any) => void;
  name?: string;
  newValue: string | null;
  option: any;
  prevValue?: string | null;
  onChangeValue?: (newValue: string | null, option: any, prevValue?: string | null) => void;
}) => {
  setFieldValue?.(name, newValue);
  // TODO(mark): Hack to avoid the Formik name: onChange.
  onChangeValue?.(newValue, option, prevValue);
};

interface BaseDropdownInputProps {
  innerRef?: React.RefObject<any>;
  autoFocus?: boolean;
  defaultMenuIsOpen?: boolean;
  disabled?: boolean;
  required?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDiscreet?: boolean;
  isHovered?: boolean;
  isPortaled?: boolean;
  isJoinedList?: boolean;
  isSingleOptionSelected?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  placeholder: string;
  menuIsOpen?: boolean;
  menuPlacement?: string;
  value?: string;
  selectedOption?: any;
  inputValue?: string;
  tabSelectsValue?: boolean;
  options: any[];
  noOptionsMessage?: (obj: {inputValue: string}) => string;
  filterOption?: (option: any, rawInput: string) => boolean;
  components?: any;
  onInputChange?: (inputValue: string, actionMeta: any) => void;
  onChangeValue?: (newValue: string | null, option: any, prevValue?: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  style?: object;
  containerStyleOverride?: object;
  valueContainerStyle?: object;
  inputStyle?: object;
  placeholderStyle?: object;
  menuListStyle?: object;
  menuStyle?: object;
  selectionStyle?: object;
  optionStyle?: object;
  backgroundColor?: string;
  fontSize?: number;
  showDescriptionInOption?: boolean;
  controlShouldRenderValue?: boolean;
  DropdownIndicatorComponent?: React.FC;
}

// Name and setFieldValue go together, if one is used the other should be used as well
// If name is not needed, and this isn't a form, onChangeValue should be used instead
interface FieldDropdownInputProps extends BaseDropdownInputProps {
  name: string;
  setFieldValue: (name: string | undefined, value: any) => void;
}

interface NoFieldDropdownInputProps extends BaseDropdownInputProps {
  name?: never;
  setFieldValue?: never;
}

type DropdownInputProps = FieldDropdownInputProps | NoFieldDropdownInputProps;

const DropdownInput = ({
  innerRef,
  autoFocus,
  defaultMenuIsOpen,
  disabled,
  required,
  isClearable,
  isLoading,
  isSearchable,
  isDiscreet,
  isHovered,
  isPortaled,
  isJoinedList,
  isSingleOptionSelected,
  isOptionDisabled,
  name,
  placeholder,
  menuIsOpen,
  menuPlacement,
  value,
  selectedOption,
  inputValue,
  tabSelectsValue,
  options,
  noOptionsMessage,
  filterOption,
  components,
  onInputChange,
  onChangeValue,
  onFocus,
  onBlur,
  onMenuOpen,
  onMenuClose,
  setFieldValue,
  style,
  containerStyleOverride,
  valueContainerStyle,
  inputStyle,
  placeholderStyle,
  menuListStyle,
  menuStyle,
  selectionStyle,
  optionStyle,
  backgroundColor,
  fontSize,
  showDescriptionInOption,
  controlShouldRenderValue,
  DropdownIndicatorComponent,
}: DropdownInputProps) => {
  const responsive = useResponsive();

  useMountEffect(() => {
    if (isSingleOptionSelected && _.size(options) === 1) {
      const option = options[0];
      const prevValue = null;
      const newValue = option ? option.value : null;
      handleChange({setFieldValue, name, newValue, prevValue, option, onChangeValue});
    }
  });

  const valueToShow = selectedOption || _.find(options, (option) => option.value === value) || '';

  return (
    <Select
      ref={innerRef}
      maxMenuHeight={500}
      autoFocus={autoFocus}
      defaultMenuIsOpen={defaultMenuIsOpen}
      isClearable={isClearable}
      isDisabled={disabled}
      isSearchable={isSearchable}
      isOptionDisabled={isOptionDisabled}
      name={name}
      isLoading={isLoading}
      placeholder={placeholder}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPortalTarget={isPortaled && document.body}
      value={valueToShow}
      tabSelectsValue={tabSelectsValue}
      inputValue={inputValue}
      options={options}
      noOptionsMessage={noOptionsMessage}
      filterOption={
        filterOption ||
        (showDescriptionInOption ? createFilter(filterConfigForDescriptionInOption) : undefined)
      }
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onChange={(option: DropdownOption) => {
        const prevValue = value;
        const newValue = option ? option.value : null;
        handleChange({setFieldValue, name, newValue, prevValue, option, onChangeValue});
      }}
      formatGroupLabel={GroupLabel}
      controlShouldRenderValue={controlShouldRenderValue}
      components={{
        ...(isClearable && valueToShow ? {} : {IndicatorSeparator: () => null}),
        ClearIndicator: (props: any) => {
          return (
            <ReactSelectComponents.ClearIndicator {...props}>
              <Icon
                source={Icon.Xmark}
                size={16}
                color={colors.gray.primary}
                style={{
                  marginLeft: 4,
                  marginRight: 4,
                  marginTop: 2,
                  cursor: disabled ? 'default' : 'pointer',
                }}
              />
            </ReactSelectComponents.ClearIndicator>
          );
        },
        ...(DropdownIndicatorComponent
          ? {
              DropdownIndicator: (props: any) => {
                return (
                  <ReactSelectComponents.DropdownIndicator {...props}>
                    <DropdownIndicatorComponent />
                  </ReactSelectComponents.DropdownIndicator>
                );
              },
            }
          : {
              DropdownIndicator: (props: any) => {
                return (
                  <ReactSelectComponents.DropdownIndicator {...props}>
                    <Icon
                      source={Icon.ChevronDown}
                      size={14}
                      color={colors.gray.secondary}
                      style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
                    />
                  </ReactSelectComponents.DropdownIndicator>
                );
              },
            }),
        Option: showDescriptionInOption ? OptionWithDescription : Option,
        Group: (props: any) => {
          const allOptions = props.selectProps.options;
          const isFirstGroup = _.get(props, 'data.label') === _.get(allOptions, '0.label');
          const isLastGroup =
            _.get(props, 'data.label') === _.get(allOptions, `${allOptions.length - 1}.label`);

          return (
            <div id={'revertGlobalStyles'}>
              <GroupContainer>
                {!isFirstGroup && <GroupDivider />}
                <Space height={12} />
                <ReactSelectComponents.Group {...props} />
                {!isLastGroup && <Space height={4} />}
              </GroupContainer>
            </div>
          );
        },
        ...components,
      }}
      styles={{
        container: (current: any, state: any) => ({
          ...current,
          pointerEvents: 'auto',
        }),
        control: (current: any, state: any) => {
          // Use containerStyleOverride instead of style if you want to override all the default styling
          const baseStyle = containerStyleOverride || current;
          return {
            'cursor': disabled ? 'default' : 'text',
            ...baseStyle,
            '&:hover': {
              borderColor: disabled
                ? INPUT_BORDER_COLOR
                : state.isFocused
                  ? colors.blue.interactive
                  : colors.blue.hover,
            },
            ...(containerStyleOverride ? {} : {...controlStyle, ...style}),
            ...(state.isFocused ? {borderColor: colors.blue.interactive} : {}),
            ...(isDiscreet ? {...getDiscreetControlStyle({state, placeholder, isHovered})} : {}),
            'pointerEvents': state.isDisabled ? 'none' : 'auto',

            // Custom 'required' feature to change the background-color.
            'backgroundColor': getBackgroundColor({
              disabled,
              required,
              backgroundColor,
            }),
          };
        },
        valueContainer: (current: any) => ({
          ...(valueContainerStyle || current),
          ...(isDiscreet ? {paddingLeft: '0px'} : {}),
        }),
        placeholder: (current: any) => ({
          ...current,
          ...defaultPlaceholderStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
          ...placeholderStyle,
        }),
        option: (current: any) => ({
          ...current,
          ...defaultOptionStyle,
          fontSize,
          ...optionStyle,
        }),
        menu: (current: any) => {
          const styles = {
            ...current,
            display: 'block',
            overflow: 'hidden',
            ...(isJoinedList
              ? {
                  marginTop: 0,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }
              : {}),
            ...menuStyle,
          };
          return styles;
        },
        menuList: (current: any) => ({
          ...current,
          display: 'block',
          ...(isJoinedList
            ? {
                paddingTop: 0,
                paddingBottom: 0,
              }
            : {}),
          ...menuListStyle,
        }),
        singleValue: (current: any, state: any) => ({
          ...current,
          ...singleValueStyle,
          color: getSingleValueColor({state}),
          ...selectionStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
        }),
        dropdownIndicator: (current: any, state: any) => ({
          ...current,
          ...(isDiscreet
            ? {
                padding: '0px',
                display: 'none',
                ...(isHovered || state.isFocused ? {display: 'flex'} : {}),
              }
            : {}),
        }),
        indicatorSeparator: (current: any) => ({
          ...current,
          ...(isDiscreet ? {display: 'none'} : {}),
        }),
        input: (current: any, state: any) => ({
          ...(inputStyle
            ? {position: !state.value ? 'absolute' : 'relative', ...inputStyle}
            : current),
          width: '100%',
          input: {
            // On mobile, setting the width of the inner input to 100% enables copy paste.
            // On desktop, this causes problems by preventing the text from filling the
            // entire width of the input.
            width: responsive.mobile ? '100% !important' : null,
            textAlign: 'left',
            fontFamily: 'Avenir',
            fontWeight: 500,
            // Removing the boxShadow on focus allows the input to focus correctly on mobile,
            // which enables copy paste actions.
            focus: {
              boxShadow: 'none',
            },
          },
        }),
      }}
    />
  );
};

DropdownInput.createFilter = createFilter;
DropdownInput.OptionWithDescription = OptionWithDescription;
DropdownInput.OptionWithDescriptionOnRight = OptionWithDescriptionOnRight;
DropdownInput.OptionWithDescriptionAndSecondaryLabel = OptionWithDescriptionAndSecondaryLabel;
DropdownInput.OptionContainer = ReactSelectComponents.Option;
DropdownInput.SingleValueContainer = ReactSelectComponents.SingleValue;
DropdownInput.Option = Option;
DropdownInput.OptionLabel = Label;
DropdownInput.OptionSecondaryLabel = SecondaryLabel;
DropdownInput.GroupContainer = GroupContainer;
DropdownInput.GroupDivider = GroupDivider;
DropdownInput.GroupLabel = GroupLabel;
DropdownInput.getBackgroundColor = getBackgroundColor;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownInput.propTypes = {
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isHovered: PropTypes.bool,
  isDiscreet: PropTypes.bool,
  isPortaled: PropTypes.bool,
  isSingleOptionSelected: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  selectionStyle: PropTypes.object,
  valueContainerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
  menuListStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  optionStyle: PropTypes.object,
  fontSize: PropTypes.number,
  menuPlacement: PropTypes.string,
  controlShouldRenderValue: PropTypes.bool,
};

DropdownInput.defaultProps = {
  isClearable: false,
  isSearchable: true,
  isHovered: false,
  isDiscreet: false,
  isPortaled: false,
  isSingleOptionSelected: false,
  tabSelectsValue: false,
  selectionStyle: {},
  valueContainerStyle: null,
  inputStyle: null,
  placeholderStyle: {},
  menuListStyle: {},
  menuStyle: {},
  optionStyle: {},
  fontSize: 14,
  menuPlacement: 'bottom',
  controlShouldRenderValue: true,
};

export default DropdownInput;
