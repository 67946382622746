// Libraries
// @ts-expect-error when we upgrade to a new version it will come with types
import {SwitchRouter, SwitchView, createNavigator} from '@react-navigation/core';
import React from 'react';

import {RoutesConfigType} from '../NavigationTypes';

// Wraps the current scene with `wrapper` which is defined in the config.
// This allows us to have a wrapper component that can listen to navigation
// changes and log analytics.
const createNavigationView = (Wrapper: React.FC<{children: React.ReactNode}>) => (props: any) => (
  <Wrapper {...props}>
    <SwitchView {...props} />
  </Wrapper>
);

const createSwitchWrapperNavigator = (routes: RoutesConfigType, config: any) => {
  const {wrapper, ...switchConfig} = config;
  const NavigationView = createNavigationView(wrapper);
  const Router = SwitchRouter(routes, switchConfig);
  return createNavigator(NavigationView, Router, {});
};

export default createSwitchWrapperNavigator;
