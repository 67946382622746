// Libraries
import {useEffect, useState} from '@supermove/hooks';
import {BackgroundGeolocation} from '@supermove/sdk';
import {LocationCoordinatesType} from '@supermove/sdk/src/BackgroundGeolocation/BackgroundGeolocationType';

interface UseBackgroundGeolocationArgs {
  timeout: number;
  distanceFilter: number;
}

/**
 * Creates a listener that listens for location changes.
 */
const useBackgroundGeolocation = ({timeout, distanceFilter}: UseBackgroundGeolocationArgs) => {
  const [location, setLocation] = useState<LocationCoordinatesType>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    BackgroundGeolocation.start({
      distanceFilter,
      timeout,
      onSuccess: (position) => {
        const {
          coords: {latitude, longitude, accuracy, altitude, floor, heading, speed},
        } = position;
        setLocation({latitude, longitude, accuracy, altitude, floor, heading, speed});
      },
      onError: (error) => {
        console.log(`[Location]: Error fetching: ${error}.`);
        setError(error);
      },
    });

    // Clean up and turn off the module.
    return () => {
      BackgroundGeolocation.stop();
    };
  }, [distanceFilter, timeout, setLocation, setError]);

  return {
    location,
    error,
  };
};

export default useBackgroundGeolocation;
