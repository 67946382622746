// Libraries
// @ts-expect-error when we upgrade to a new version it will come with types
import {SwitchRouter, SwitchView, createNavigator} from '@react-navigation/core';
import React from 'react';

import {RoutesConfigType} from '../NavigationTypes';

// Wraps the current scene with `component` which is defined in the config.
// This allows us to have a wrapper component with a permanent Sidebar that encapsulates
// the SwitchView routes.
const createNavigationView =
  ({component: Component}: {component: React.FC<{children: React.ReactNode}>}) =>
  (props: Record<string, unknown>) => (
    <Component>
      <SwitchView {...props} />
    </Component>
  );

// TODO(jay) add config types when react navigation upgraded upgraded
const createSidebarNavigator = (routes: RoutesConfigType, config: any) => {
  const {sidebar, ...switchConfig} = config;
  const NavigationView = createNavigationView(sidebar);
  const Router = SwitchRouter(routes, switchConfig);

  return createNavigator(NavigationView, Router, {});
};

export default createSidebarNavigator;
