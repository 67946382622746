// Libraries
import React from 'react';

// Components
import FileDragInput from '../FileDragInput';

import {CameraInputProps} from './cameraInputTypes';

/**
 * On web, we use the FileDragInput for the CameraInput.
 */
const CameraInput = ({disabled, onFilesChange, children, webOptions, style}: CameraInputProps) => {
  return (
    <FileDragInput
      disabled={disabled}
      onFilesChange={onFilesChange}
      children={children}
      webOptions={webOptions}
      style={style}
    />
  );
};

export default CameraInput;
