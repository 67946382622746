// Libraries
// @ts-expect-error when we upgrade to a new version it will come with types
export {createBrowserApp} from '@react-navigation/web';
// @ts-expect-error when we upgrade to a new version it will come with types
export {NavigationEvents, withNavigation, createSwitchNavigator} from '@react-navigation/core';

// Apps
export {default as createServerApp} from './apps/createServerApp';

// Components
export {default as Head} from './components/Head';
export {default as Link} from './components/Link';
export {default as Redirect} from './components/Redirect';

// Hooks
export {default as useActiveRoute} from './hooks/useActiveRoute';
export {default as useHead} from './hooks/useHead';
export {default as useIsFocused} from './hooks/useIsFocused';
export {default as useNavigation} from './hooks/useNavigation';

// Navigators
export {default as createSidebarNavigator} from './navigators/createSidebarNavigator';
export {default as createStackNavigator} from './navigators/createStackNavigator';
export {default as createSwitchWrapperNavigator} from './navigators/createSwitchWrapperNavigator';
export {default as createStackWrapperNavigator} from './navigators/createStackWrapperNavigator';

//
// Manager only
//
// Intentionally importing from the DOM version of the hook here
// eslint-disable-next-line
export {default as useNavigationDOM} from './hooks/useNavigationDOM.dom';
// Intentionally importing from the DOM version of the hook types here
// eslint-disable-next-line
export * from './hooks/useNavigationDOM.dom';
