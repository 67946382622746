import {Editor} from '@tiptap/react';

// Loops through all nodes for a given attribute, return when it finds a match
export const findAttribute = (editor: Editor, nodesToCheck: string[], attribute: string) => {
  for (const node of nodesToCheck) {
    const attributeValue = editor.getAttributes(node)?.[attribute];
    if (attributeValue) {
      return attributeValue;
    }
  }
};
