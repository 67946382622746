// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Local
import TextTooltip from '@shared/design/components/TextTooltip';

import TertiaryButton from '../Button/TertiaryButton';

const Container = Styled.View`
`;

const getColor = ({
  isSecondary,
  isDestructive,
  isDisabled,
  isDisplayHovered,
}: {
  isSecondary?: boolean;
  isDestructive?: boolean;
  isDisabled?: boolean;
  isDisplayHovered?: boolean;
}) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (isDestructive) {
    return colors.red.warning;
  }
  if (isDisplayHovered) {
    return colors.blue.hover;
  }
  if (isSecondary) {
    return colors.gray.secondary;
  }
  return colors.blue.interactive;
};

const TooltipWrapper = ({
  tooltip,
  isEnabledMobileToastTooltip,
  children,
}: {
  tooltip?: string;
  isEnabledMobileToastTooltip?: boolean;
  children: React.ReactNode;
}) => {
  if (tooltip) {
    return (
      <TextTooltip text={tooltip} isEnabledMobileToast={isEnabledMobileToastTooltip}>
        <Container>{children}</Container>
      </TextTooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const IconButton = ({
  source,
  onPress,
  isSecondary,
  isDestructive,
  isDisabled,
  isDisplayOnly,
  isHoverDistinct,
  buttonStyle = {},
  children,
  size = 16,
  tooltip,
  isHovered,
  isEnabledMobileToastTooltip,
}: {
  source: IconSource;
  onPress?: () => void;
  isSecondary?: boolean;
  isDestructive?: boolean;
  isDisabled?: boolean;
  isDisplayOnly?: boolean;
  isHoverDistinct?: boolean;
  buttonStyle?: object;
  children?: React.ReactNode;
  size?: number;
  tooltip?: string;
  isHovered?: boolean;
  isEnabledMobileToastTooltip?: boolean;
}) => {
  const internalHover = useHover();
  const responsive = useResponsive();
  const isDisplayHovered =
    responsive.desktop && (isHovered || (isHoverDistinct && internalHover.isHovered));

  return (
    <TertiaryButton
      style={{
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        ...(isHoverDistinct ? {borderWidth: 1, borderRadius: 4, borderColor: 'transparent'} : {}),
        ...(isDisplayHovered && !isDisabled
          ? {backgroundColor: colors.gray.background, borderColor: colors.gray.border}
          : {}),
        ...buttonStyle,
      }}
      onPress={onPress}
      isDisabled={isDisabled || isDisplayOnly}
      buttonRef={isHoverDistinct ? internalHover.ref : undefined}
    >
      {children || (
        <TooltipWrapper
          tooltip={tooltip}
          isEnabledMobileToastTooltip={isDisabled && isEnabledMobileToastTooltip}
        >
          <Icon
            source={source}
            size={size}
            color={getColor({isSecondary, isDestructive, isDisabled, isDisplayHovered})}
          />
        </TooltipWrapper>
      )}
    </TertiaryButton>
  );
};

IconButton.SOURCE = Icon;

export default IconButton;
